import React from "react";
import {useAppDispatch, useAppSelector} from "../state/hooks";
import {toggleOption, setColor} from "../state/slices/diagramSlice";
import ColorPicker from "./ColorPicker";

export interface ItemProps {
  [key: string]: boolean
}




function Options() {
    const options = useAppSelector(state => state.diagram.options)
    const colors = useAppSelector(state => state.diagram.colors)

    const dispatch = useAppDispatch()

    const itemList = (options: ItemProps) => Object.keys(options).map((i, n) => {

        return (
            <span className={"tag p-0"} style={{background: "#E6E6E6"}} key={i}>
                <div className="tags has-addons" onClick={() => dispatch(toggleOption(i))}>
                    <span className={"tag " + ((options[i])?"is-success":"is-danger")}></span>
                    <span className="tag is-dark">{i}</span>
                </div>
            </span>
        )
    })

  return (
      <div className={"has-text-centered-mobile"}>
          <div className={"subtitle"}>Options</div>
          <div className={"columns level"}>
              <div className={"column level-item"}>
                  <div className={"tags is-centered"}>
                      {itemList(options)}
                  </div>
              </div>
              <div className={"column level-item"}>
                  <div className={"field"}>

                      <ColorPicker color={colors["Background"]} setColor={(kv) => dispatch(setColor(kv))}
                                   label={"Background"} name={"Background"} centered={true}
                      />

                      {/*<ColorPicker color={colors["Table"]} setColor={(kv) => dispatch(setColor(kv))} label={"Table"} name={"Table"} centered={true} />
*/}
                  </div>
              </div>
          </div>

      </div>

  );
}

export default Options;