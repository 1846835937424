import React from "react";
import {useAppDispatch} from "../state/hooks";
import {useNavigate} from "react-router-dom";
import {restructureState} from "./builder";
import {newProject} from "../state/slices/projectSlice";
import {replaceSlice, stateToString} from "../state/slices/diagramSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";


const ExampleDiagram = (props: { title: string, description: string | React.ReactElement , file: string}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    function setExample(example_json_file: string) {
    //TODO - something like the restoreState function from builder to set the diagram up, then a navigation to the builder to view
    fetch(example_json_file, {
        headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }).then(response => {
        return response.text()
    }).then(text => {
        let restructured = restructureState(text)
        //console.log(restructured)
        let split_name = example_json_file.replace("examples/", "").split(".")
        let file_name = ( split_name.length > 1) ? split_name[0] : "example";
        dispatch(newProject({
                    name: file_name,
                    diagram: stateToString(restructured, 0)
                }));
        dispatch(replaceSlice(restructured))
        navigate('/builder');
      })
}

    return <div className="tile is-parent">
        <article className="tile is-child notification has-text-centered">
            <article className="media">
                <figure className="image is-inline-block is-128x128">
                    <img src={props.file} alt="Example berd diagram" style={{mixBlendMode: "multiply"}}></img>
                </figure>
                <div className="media-content">
                    <div className="content">
                        <p> {props.title} </p>
                    </div>
                    <p> {props.description} </p>
                    {/*<nav className="level is-mobile">
                        <div className="level-left">
                            <a className="level-item">

                                <span className={"icon is-small"}>
                                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                </span>
                                <span>Download</span>

                            </a>
                        </div>
                    </nav>*/}
                </div>
                <div className="media-right">
                    {/* Assume both the svg and json in the public/examples folder */}
                    <span className={"icon is-small"} onClick={()=>{setExample(props.file.replace("svg", "json"))}}>
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </span>
                </div>
            </article>
        </article>
           </div>
}

export default ExampleDiagram