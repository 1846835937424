import React from "react";
import {KVProps} from "../state/slices/diagramSlice";

interface InputProps {
    name: string,
    color: string,
    setColor: (arg0: KVProps)=>void,
}
const ColorInput = (props: InputProps) => {
    return (
        <input className="input"
                   type="text"
                   onChange={(e) => { props.setColor({ key: props.name, value: e.currentTarget.value.replace(/[#]+\W/g, '') }) }}
                   value={props.color}
        >
        </input>
    )
}
export default ColorInput