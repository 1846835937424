import React, {useEffect, useRef, useState} from "react";
interface OptionalSelectProps {
    options: string[],
    placeholder: string,
    value: string,
    onSelection: (selection: string)=>void
}

function OptionalSelect(props: OptionalSelectProps) {
    const [searchTerm, setSearchTerm] = useState(props.value);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string|null>(null);

    const dropdownRef = useRef<HTMLInputElement>(null);

    const filteredOptions = props.options.filter(option =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSelect = (option: string) => {
        //alert("handle select" + option);
        props.onSelection(option);
        setSelectedOption(option);
        setSearchTerm(option);
        setIsOpen(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && searchTerm) {
            handleSelect(searchTerm);
        }
    };

    useEffect(() => {
        if (filteredOptions.length === 0) {
            handleSelect(searchTerm);
        }
    }, [searchTerm])

    return <div className={`dropdown ${isOpen ? 'is-active' : ''}`} style={{width: '100%'}}>
        <div className="dropdown-trigger" style={{width: '100%'}}>
            <input
                className="input"
                type="text"
                placeholder={props.placeholder}
                value={searchTerm}
                onClick={() => setIsOpen(!isOpen)}
                ref={dropdownRef}
                onBlur={(e) => {
                        handleSelect(searchTerm)

                }}
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setIsOpen(true);
                }}
                onKeyDown={handleKeyDown}
            />
            {isOpen && (
            <div className="dropdown-menu" role="menu" style={{width: '100%'}}>
                <div className="dropdown-content">
                    {filteredOptions.length > 0 ? (
                        filteredOptions.map((option, index) => (
                            <a
                                key={index}
                                className="dropdown-item"
                                onMouseDown={(e) => {
                                        handleSelect(option)
                                    }
                                }
                            >
                                {option}
                            </a>
                        ))
                    ) : (
                        <div className="dropdown-item">No options found</div>
                    )}
                </div>
            </div>
        )}
        </div>

    </div>
}

export default OptionalSelect