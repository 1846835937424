import { configureStore } from '@reduxjs/toolkit'
import diagramReducer from './slices/diagramSlice'
import projectReducer from './slices/projectSlice'

//ImmerError:
//Uncaught Error: [Immer] The plugin for 'MapSet' has not been loaded into Immer. To enable the plugin, import and call `enableMapSet()` when initializing your application.
//From: https://github.com/reduxjs/redux-toolkit/issues/466
import { enableMapSet } from 'immer'
import {loadState} from "./localStorage";
enableMapSet()
//ImmerError: End

const savedState = loadState();

export const store = configureStore({
  reducer: {
    diagram: diagramReducer,
    project: projectReducer,
  },
  preloadedState: savedState,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch