import React from "react";

const QA = (props: { q: string, a: string | React.ReactElement }) => {
    return <div className="tile is-parent">
        <article className="tile is-child notification">
            <p className="title">{props.q}</p>
            <p>{props.a}</p>
        </article>
           </div>
}

export default QA