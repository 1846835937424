import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../state/hooks";
import {
    setDiagramSVG,
    stateToString
} from "../state/slices/diagramSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faExpand, faCompress} from "@fortawesome/free-solid-svg-icons";
import {base64_font} from "../fonts/FireSans";

interface DiagramProps {
    isExpanded: boolean,
    setFormsVisible: Dispatch<SetStateAction<boolean>>
}



function Diagram(props: DiagramProps) {
    const currentState = useAppSelector(state => state.diagram)
    const components = useAppSelector(state => state.diagram.components)
    const ships = useAppSelector(state => state.diagram.ships)
    const options = useAppSelector(state => state.diagram.options)
    const colors = useAppSelector(state => state.diagram.colors)
    const dispatch = useAppDispatch()
    const diagramSVG = useAppSelector(state => state.diagram.diagramSVG)
    //const [inFlight, setInFlight] = useState<AbortController>(new AbortController());
    const [inFlightDelay, setInFlightDelay] = useState<NodeJS.Timeout>(setTimeout(() => {return Promise.resolve(false)}, 1))

//changing destination to API gateway to enable caching and potentially throttling in future
//changing back. POST is not cachable on API Gateway so pointless

//https://berd.azure-api.net/berd/http_berd  //API Gateway
//https://berd.azurewebsites.net/api/http_berd  //Function App

    /*const downloadSVG = () => {

        /!*fetch(svg_url)
            .then((res) => {
                return res.text();
            })
            .then((data) => {*!/
                //console.log("data", data)
                let rando_key = Math.random().toString().substring(2,5);
                let elem = document.createElement("a");
                elem.setAttribute("href", "data:text/plain," + diagramSVG);
                elem.setAttribute("type", "image/svg+xml");
                elem.setAttribute("download", `berd_${rando_key}.svg`);
                elem.style.display = "none";
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);

                //aaand shortly thereafter download the state tree
                setTimeout(() => {
                    let data = stateToString(currentState);
                    console.log(data)
                    let elem = document.createElement("a");
                    elem.setAttribute("href", "data:text/plain," + encodeURIComponent(data));
                    elem.setAttribute("type", "text/plain");
                    elem.setAttribute("download", `berd_${rando_key}.json`);
                    elem.style.display = "none";
                    document.body.appendChild(elem);
                    elem.click();
                    document.body.removeChild(elem);
                }, 1000)

            /!*})
            .catch((error) => {
                console.log("Error getting SVG", error)
            })*!/
    }*/


    /*useEffect(() => {

        let racing = [inFlightDelay, Promise.resolve(false)]
        Promise.race(racing).then((res) => {
            if (!res) {
                inFlight.abort()
                let ac = new AbortController()
                setInFlight(ac)
            }
        })
        //set up new AbortController so I cancel the fetch if the component re-renders

    }, [inFlight, inFlightDelay])*/

    useEffect(() => {
        clearTimeout(inFlightDelay)
      const func_svg_url = (window.location.hostname === "localhost")?"http://localhost:7071/api/http_berd":"https://berd.azurewebsites.net/api/http_berd";
        if ( Object.keys(components).length > 0 || Object.keys(ships).length > 0 ) {
            let payload = JSON.stringify({
                "berd_payload": {
                    "components": components,
                    "relationships": ships,
                    "options": options,
                    "colors": colors
                }
            });

            //console.log("fetch with: ", payload)
            //let inFlightTimeout = setTimeout(() => {
            let inFlightTimeout = setTimeout(() => {
                fetch(func_svg_url, {
                    method: "POST",
                    //signal: inFlight.signal,
                    body: payload,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                    .then((res) => {
                        return res.text();
                    })
                    .then((data) => {

                        /* font injection... */

                        let p = new DOMParser()
                        let d = p.parseFromString(data, "image/svg+xml")
                        //console.log(d)
                        let svg = d.children[0]
                        let defs = svg.getElementsByTagName("defs")
                        let style = document.createElementNS("http://www.w3.org/2000/svg", 'style');

                        //let existingStyle = svg.getAttribute("style")


                        //insert style into the svg
                        let def0 = defs.item(0)
                        if (def0) {
                            //def0.parentNode.insertBefore(style, defs.item(0))
                            def0.appendChild(style)
                        }

                        style.type = "text/css"

                        //first converted from ttf:
                        //woff2_compress FiraSans-Regular.ttf
                        //base64 FiraSans-Regular.woff2
                        style.innerHTML = `
                        @font-face {
                            font-family: "Fira Sans";
                            src: url(data:font/woff2;base64,${base64_font});
                        }
                    `
                        //svg.setAttribute("style", existingStyle + new_style)

                        //console.log(d)
                        let new_data = new XMLSerializer().serializeToString(svg);

                        /* End of font injection */
                        console.log("updating svg")
                        dispatch(setDiagramSVG(encodeURIComponent(new_data)))
                    })
                    .catch((error) => {
                        console.log("Error getting SVG", error)
                    })
                return Promise.resolve(true)
            }, 400)
            setInFlightDelay(inFlightTimeout)
        }

    }, [components, ships, options, colors]);



  return (
      <div className={"box content"}>
          <div className={"control has-text-right"}>
              <button className={"button"} onClick={() => props.setFormsVisible(props.isExpanded)}>
                        <span className={"icon"}>
                            {(props.isExpanded) ? <FontAwesomeIcon icon={faCompress}/> :
                                <FontAwesomeIcon icon={faExpand}/>}
                        </span>
              </button>
          </div>
          {/* Could use style={{mixBlendMode: "multiply"}} here to get rid of white background but currently prefer the white box*/}
          <figure className="image" style={{marginLeft: "auto", marginRight: "auto"}}>
              {(diagramSVG === "") ?
                  <img src="berd_placeholder.svg" alt="Placeholder berd diagram"></img>
                  :
                  <img src={"data:image/svg+xml," + diagramSVG} alt="berd diagram"></img>}
          </figure>


      </div>
  );
}

export default Diagram;
