import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface ProjectSlice {
    existingProjectNames: string[],
    projects: Project[],
    currentProject: string,
}

export interface Project {
    name: string,
    diagram: string,
    version?: number,
}

export const sliceInitial: ProjectSlice = {
    existingProjectNames: [] as string[],
    projects: [] as Project[],
    currentProject: ""
}


//using normalised state shape with Ids in objects instead of arrays as it's quite natural for a database model!
export const projectSlice = createSlice({
    name: 'project',
    initialState: sliceInitial,
    reducers: {
        newProject: (state, action: PayloadAction<Project>) => {
            let project = action.payload;
            project.version = project.version ?? 1;
            if (!state.existingProjectNames.includes(project.name) ) {
                state.existingProjectNames.push(project.name);
                state.projects.push(project);
            }
            state.currentProject = project.name
        },
        removeProject: (state, action: PayloadAction<string>) => {
            let project = action.payload;
            //console.log(`"Removing ${project}`)
            state.currentProject = ""
            for (let i = state.existingProjectNames.length; i > 0; i--) {
                if ( state.existingProjectNames[i-1] === project ) {
                    state.existingProjectNames.splice(i-1, 1)
                }
            }
            for ( let i = state.projects.length; i > 0; i-- ) {
                if (state.projects[i-1].name === project) {
                    state.projects.splice(i-1, 1)
                }
            }
        },
        //changes the selected project, so just the name in the selection
        changeProject: (state, action: PayloadAction<string>) => {
            let project = action.payload;
            state.currentProject = project
        },
        //modify the contents of a project, that is, the diagram
        updateProject: (state, action: PayloadAction<Project>) => {
            let project = action.payload;
            state.currentProject = project.name
            for ( let i = state.projects.length; i > 0; i-- ) {
                if (state.projects[i-1].name === project.name) {
                    state.projects.splice(i-1, 1, project)
                }
            }
        },
        versionIncrement: (state) => {
            for ( let i = state.projects.length; i > 0; i-- ) {
                if (state.projects[i-1].name === state.currentProject) {
                    state.projects.splice(i-1, 1, {
                        ...state.projects[i-1],
                        version: (state.projects[i-1].version ?? 0) + 1
                    })
                }
            }
        },
    },
})

export const {
    newProject,
    removeProject,
    changeProject,
    updateProject,
    versionIncrement,
} = projectSlice.actions

export default projectSlice.reducer