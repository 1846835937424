import React from "react";
import ExampleDiagram from "./ExampleDiagram";



function Examples() {

  return (
      <div style={{height: "100%"}}>
          <section className="hero is-primary">
              <div className="hero-body">
                  <p className="title">
                      Examples
                  </p>
              </div>
          </section>
          <div className={"hero is-halfheight "}>
              <div className={"hero-body container"}>
                  <div className="tile is-ancestor">
                      <div className="tile is-vertical">

                          {/* Row 1 */}
                          <div className="tile">

                              <ExampleDiagram title={"Mixed Conceptual"}
                                              description={"Shows a database table, components with fields, and an abstract process"}
                                              file={"examples/berd_example.svg"}
                              />

                              <ExampleDiagram title={"Grouping"}
                                              description={"Shows grouped boxes and connections between components"}
                                              file={"examples/berd_example_groups.svg"}
                              />

                              <ExampleDiagram title={"Aliasing"}
                                              description={"Shows two components which share a database table"}
                                              file={"examples/berd_example_alias.svg"}
                              />


                          </div>

                          {/* Row 2 */}
                          {/*
                        <div className="tile">

                            <div className="tile is-parent">
                                <article className="tile is-child notification">
                                    <p className="title">Q3?</p>
                                    <p>A3</p>
                                </article>
                            </div>

                            <div className="tile is-parent">
                                <article className="tile is-child notification">
                                    <p className="title">Q4?</p>
                                    <p>A4</p>
                                </article>
                            </div>

                        </div>
                        */}


                      </div>
                  </div>


              </div>
          </div>
      </div>

  );
}

export default Examples;