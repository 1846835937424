import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {localconsole} from "../../App";
import {Draft} from "immer";

export interface KVProps {
    key: string,
    value: string
}

//optional name and db_name so the user can specify either
export interface Column {
    id: number,
    name?: string,
    db_name?: string,
}

export enum ColumnFieldsDictionary {
    name,
    db_name
}

//component of the diagram, might be a database table or just an object
//optional name and db_name so the user can specify either
export interface Comp {
    id: number,
    name?: string,
    db_name?: string,
    alias?: string,
    description?: string,
    columns: Column[],
    colors?: ColorDictionary,
}

export enum CompFieldsDictionary {
    name,
    db_name,
    alias,
    description,
    colors,
}

export interface CompDictionary {
  [key: string]: Comp
}

//from and to correspond to the index number of the component in the components array
export interface Ship {
  id: number,
  from: string,
  to: string,
  cardinality: string,
  from_col?: string,
  to_col?: string,
  freestyle?: string,
  colors?: ColorDictionary,
}

export enum ShipFieldsDictionary {
    from,
    to,
    cardinality,
    fromCol,
    toCol,
    freestyle,
    colors,
}

export interface ShipDictionary {
  [key: string]: Ship
}

export interface OptionDictionary {
  [key: string]: boolean
}

export interface ColorDictionary {
  [key: string]: string
}

export function getCompReference(item: Comp): string | undefined {
    if (item.name && item.db_name && item.alias) {
        return item.alias
    }
    if (!item.db_name && !item.alias) {
        return item.name
    }
    if (!item.name && !item.alias) {
        return item.db_name
    }
    if (!item.name && !item.db_name) {
        return item.alias
    }
    if (!item.db_name) {
        //assume name and alias
        return item.alias
    }
    if (!item.alias) {
        //assume name and table
        return item.name
    }
    if (!item.name) {
        //assum table and alias
        return item.alias
    }

    //return (!item.name && item.db_name)?"<<" + (item.db_name??"").toLowerCase() + ">>":item.name
    return item.name
}

function getObjectWithAlias(objs: Comp[], alias: string) {
    for (let k in objs) {
        const c: Comp = objs[k];
        if (c.alias === alias || c.name === alias) {
            return c
        }
    }
    return null
}

export interface DiagramSlice {
    existingComponentIds: number[],
    components: CompDictionary,
    existingShipIds: number[],
    ships: ShipDictionary,
    options: OptionDictionary,
    colors: ColorDictionary,
    diagramSVG: string,
}

export const sliceInitial: DiagramSlice = {
    existingComponentIds: [] as number[],
    components: {} as CompDictionary, //e.g. 1: Table
    existingShipIds: [] as number[],
    ships: {} as ShipDictionary, //e.g. 1: Ship
    options: {
        "Legend": true,
        "Orthogonal": true,
        "Fields": true,
        "Gantt": false,
    } as OptionDictionary,
    colors: {
        "Background": "#8e3d67"
    },
    diagramSVG: "",
}



export function stateToString(code: DiagramSlice, spaces?: number) {
    localconsole("ENTER: stateToString")
    //localconsole(code)
    let displayCode = {
        ...{
            ...code,
            components : Object.entries(code.components).map(([i, compInstance]) => { return {...compInstance, id: undefined, columns: compInstance.columns.map((column) => {return {...column, id:undefined}})} }),
            ships: Object.entries(code.ships).map(([i, shipInstance]) => { return {...shipInstance, id:undefined} })
        },
        //existingComponentIds: Object.entries(code.components).map(([i, compInstance]) => compInstance.id),
        //existingShipIds: Object.entries(code.ships).map(([i, shipInstance]) => shipInstance.id),
        existingComponentIds: undefined,
        existingShipIds: undefined,
        diagramSVG: undefined,
    }
    //localconsole("exiting")
    return JSON.stringify(displayCode, null, spaces ?? 2)
}

const upsertTable = (state: Draft<DiagramSlice>, comp: Comp) => {
    //use spread operator to gather existing components and then address specifically the one we're adding/updating
    if (!state.existingComponentIds.includes(comp.id) ) { state.existingComponentIds.push(comp.id) }
      state.components = {
        ...state.components,
        [comp.id]: comp
      }
}

//using normalised state shape with Ids in objects instead of arrays as it's quite natural for a database model!
export const diagramSlice = createSlice({
  name: 'diagram',
  initialState: sliceInitial,
  reducers: {
    resetSlice: (state) => {
      return sliceInitial;
    },
    replaceSlice: (state, action) => {
        let newSlice = action.payload
        if (newSlice.options.Fields === undefined) {
            newSlice.options.Fields = true
        }
      return newSlice;
    },
    addTable: (state, action: PayloadAction<Comp>) => {
      upsertTable(state, action.payload)
    },
    delTable: (state, action: PayloadAction<number>) => {
        localconsole(`ENTER: delTable: ${action.payload}`)
      const component_id = action.payload;
      //the [key: number] bit specifies the type of the object that's in the components property so that it can be indexed and removed
      const newComponents: {[key: number] : Comp} = { ...state.components };
      //remove a property from the object
      delete newComponents[component_id];
      state.components = newComponents;
      state.existingComponentIds = state.existingComponentIds.filter((num) => num !== component_id)
    },
    copyTable: (state, action: PayloadAction<number>) => {
        let component = {...state.components[action.payload]};
        const new_id = ( ( state.existingComponentIds.length > 0 ) ? Math.max( ...state.existingComponentIds, 0 ) + 1 : 1 )
        component.id = new_id
      if (!state.existingComponentIds.includes(new_id) ) { state.existingComponentIds.push(new_id) }
      state.components = {
        ...state.components,
        [new_id]: component
      }
    },
    addShip: (state, action: PayloadAction<Ship>) => {
        localconsole("ENTER: addShip")
        //use spread operator to gather existing ships and then address specifically the one we're adding/updating
        const ship = action.payload;
        localconsole(state.ships)
        localconsole(state.existingShipIds)
        if ( !state.existingShipIds.includes(ship.id) ) { state.existingShipIds.push(ship.id) }
        //validate the entry for cardinality, 1:1 is just a line so is a catch all at the moment
        ship.cardinality = (["1:M", "M:1", "ZoO", "ExO", "ZoM", "OoM", "DoT", "ArO", "---", "Cmp", "Agg", "Ext"].includes(ship.cardinality))?ship.cardinality:"1:1"
        state.ships = {
            ...state.ships,
            [ship.id]: ship
        }
    },

    delShip: (state, action: PayloadAction<number>) => {
      const ship_id = action.payload;
      //the [key: number] bit specifies the type of the object that's in the ships property so that it can be indexed and removed
      const newShips: {[key: number] : Ship} = { ...state.ships };
      //remove a property from the object
      delete newShips[ship_id];
      state.ships = newShips;
      state.existingShipIds = state.existingShipIds.filter((num) => num !== ship_id)
    },
      copyShip: (state, action: PayloadAction<number>) => {
        let ship = {...state.ships[action.payload]};
        const new_id = ( ( state.existingShipIds.length > 0 ) ? Math.max( ...state.existingShipIds, 0 ) + 1 : 1 )
        ship.id = new_id
      if (!state.existingShipIds.includes(new_id) ) { state.existingShipIds.push(new_id) }
      state.ships = {
        ...state.ships,
        [new_id]: ship
      }
      },
      toggleColumn: (state, action: PayloadAction<{component_id: number, column_name: string}>) => {

        let component = state.components[action.payload.component_id]

        if (component.columns.some(c => c.name === action.payload.column_name)) {
            let cols: Column[] = component.columns.filter((i) => i.name !== action.payload.column_name)
            const t: Comp = {
                ...component,
                "columns": cols
            };
            upsertTable(state, t)
        } else {
            const t: Comp = {
                ...component,
                columns: component.columns.concat([ { id: component.columns ? Math.max( ...component.columns.map( c => c.id ), 0 ) + 1 : 1, name: action.payload.column_name } ])
            }
            upsertTable(state, t)
        }
      },
      toggleOption: (state, action: PayloadAction<string>) => {
        if (Object.keys(sliceInitial.options).includes(action.payload)) {
            if (action.payload === "Gantt" && window.location.hostname !== "localhost") {
                //temporarily do not allow Gantt as I have not finished the code
                console.log("Gantt is disabled until feature is complete")
            } else {
                state.options[action.payload] = !(!!(state.options[action.payload]))
            }
        }
      },
      setColor: (state, action: PayloadAction<KVProps>) => {
        //console.log("Setting color: ")
        state.colors[action.payload.key] = action.payload.value
      },
      setDiagramSVG: (state, action: PayloadAction<string>) => {
        state.diagramSVG = action.payload
      },
      sortComps: (state) => {
        //converts dictionary to array, sorts it by component reference, remaps the key values by index, assigns back to state
            state.components = Object.fromEntries(Object.entries(state.components).sort(
                ([k1, c1], [k2, c2]) => (getCompReference(c1) ?? ""
                ).localeCompare((getCompReference(c2) ?? ""))).map(
                    ([k,v] ,i) => {
                        v.id = i + 1
                        return [i + 1, v]
                    }
            ))
          state.existingComponentIds = Object.entries(state.components).map(([k, c]) => c.id)
        },
      sortShips: (state) => {
        localconsole("ENTER: sortShips")
        //converts dictionary to array, sorts it by relationship origin, remaps the key values by index, assigns back to state
        state.ships = Object.fromEntries(Object.entries(state.ships).sort(([k1, s1], [k2, s2]) => {
                return s1.from.localeCompare(s2.from)
            }).map(
                ([k,v] ,i) => {
                    v.id = i + 1
                    return [i + 1, v]
                }
        ))
          localconsole(state.ships)
          state.existingShipIds = Object.entries(state.ships).map(([k, c]) => c.id)
          localconsole(state.existingShipIds)
      },
  },

})

export const {
    resetSlice,
    replaceSlice,
    addTable,
    delTable,
    addShip,
    delShip,
    toggleColumn,
    toggleOption,
    setColor,
    copyTable,
    copyShip,
    setDiagramSVG,
    sortComps, /* TODO - Standardise action names. Table vs Comps */
    sortShips,
} = diagramSlice.actions

export default diagramSlice.reducer