//increment the version so I can 'wipe' it
import {DiagramSlice, sliceInitial as initialDiagram} from "./slices/diagramSlice";
import {ProjectSlice, sliceInitial as initialProject} from "./slices/projectSlice";
import {localconsole} from "../App";

const old_versions = ["berd_v1.0"] as string[]
const app_version = 'berd_v1.1';  //increment this to reset local state when new version is released, so the slate is clean

//this function is used as a handler in the store subscribe() method, it's attached in the app and called when a state change happens
export const onStateChange = (state: any) => {
    try {
        let diagrams = { diagram: state.diagram, project: state.project };
        let stateString = JSON.stringify(diagrams);
        localStorage.setItem(app_version, stateString);
    } catch(err) {
        //check if localStorage is full
        console.log(err);
        console.log("localStorage is full, remove some diagrams")
    }
};

//loadState from localStorage using current tree version
export const loadState = () => {
    localconsole("ENTER: loadState")
    let ret = undefined;
    try {
        let stateString = localStorage.getItem(app_version);
        if (stateString !== null) {
            let savedState = JSON.parse(stateString);
            //fill in any missing state
            {
                type diagramKey = keyof DiagramSlice
                let keys: diagramKey[] = Object.keys(initialDiagram) as diagramKey[]
                keys.forEach((k: diagramKey) => {
                    if (savedState.diagram[k] === undefined) {
                        savedState.diagram[k] = initialDiagram[k]
                    }
                    if (k === "options") {
                        if (savedState.diagram[k].Fields === undefined) {
                            savedState.diagram[k].Fields = true
                        }
                        if (savedState.diagram[k].Gantt === undefined) {
                            localconsole("setting Gantt option to false")
                            savedState.diagram[k].Gantt = false
                        }
                    }
                })
            }
            {
                type projectKey = keyof ProjectSlice
                let keys: projectKey[] = Object.keys(initialProject) as projectKey[]
                keys.forEach((k: projectKey) => {
                    if (savedState.project[k] === undefined) {
                        savedState.project[k] = initialProject[k]
                    }
                    if (savedState.project.version === undefined) {
                        savedState.project.version = 1
                    }
                })
            }
            ret = savedState;
        }

        //tidy up the old state
        for (let i in old_versions) {
            localStorage.removeItem(old_versions[i]);
        }
    } catch (err) {
        console.log(err);
    }
    return ret;
};