import React from "react";
import {useAppDispatch, useAppSelector} from "../state/hooks";
import {
    addTable,
    Column,
    ColumnFieldsDictionary,
    Comp,
} from "../state/slices/diagramSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarPlus, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import CompsFieldInput from "./CompsFieldInput";
import OptionalSelect from "./optionalSelect";

interface ColProps {
    active_component_id: number,
    options?: string[],
}



function Cols(props: ColProps) {
    const active_component = useAppSelector(state => state.diagram.components[props.active_component_id])

    const dispatch = useAppDispatch()

    const newItem = (e: React.MouseEvent<HTMLButtonElement>) => {
        let column_ids = active_component.columns.map( c => c.id )
        //console.log( active_component.columns )
        dispatch(addTable({
            ...active_component,
            columns: active_component.columns.concat([ { id: active_component.columns ? Math.max( ...column_ids, 0 ) + 1 : 1 } ])
        }))
    }



    const binCol = (item_id: number) => {
        // get the number from the data-key attribute on the row div
        //const elem = (e.currentTarget.parentElement)?e.currentTarget.parentElement.parentElement:null;
        //if ( elem != null ) {
            //let elemId = elem.getAttribute("data-key")
            //let id = (elemId && elemId!= null)?parseInt(elemId):0;

            //console.log(id)
            //console.log(active_component)
            if ( active_component ) {
                let cols: Column[] = active_component.columns.filter((i) => i.id !== item_id)
                const t: Comp = {
                    ...active_component,
                    "columns": cols
                };

                dispatch(addTable(t))

            }
        //}
    }

    const updateColumn = (column: Column) => {
        let cols = active_component.columns.filter((c) => c.id !== column.id)
        cols.splice(column.id - 1, 0, column)
        let t = {
            ...active_component,
            columns: cols
        }


        dispatch(addTable(t))
    }


    //if options are passed, only show columns in the available options, others are hidden. If there are no options, show all the fields for the component. This supports the Events for Gantt charts using columns to hold properties displayed as fields
    const itemList = active_component ? active_component.columns.map((i) =>
            props.options && i.name && !props.options.includes(i.name) ? <tr></tr>  :
                <tr key={i.id} data-key={i.id} className={"has-text-centered"}>
                    <td>
                        <div className={"field has-addons"}>
                            <div className={"control is-expanded"}>
                                {
                                    props.options ?
                                            <div className="select is-fullwidth is-small">
                                                <select id={"event_select_" + i.id}
                                                        onChange={(e) => updateColumn({
                                                            ...i,
                                                            name: e.currentTarget.value
                                                        })}
                                                        defaultValue={i.name}


                                                >
                                                    <option disabled selected>Select an option</option>
                                                    {props.options.map((o) =>
                                                        <option value={o}>{o}</option>
                                                    )}


                                                </select>


                                            </div> :
                                        <CompsFieldInput record={i} field={ColumnFieldsDictionary.name}
                                                         setRecord={(c) => updateColumn(c)}/>
                                }

                            </div>
                            <div className={"control"}>
                                <button className={"button is-danger is-small"} onClick={() => binCol(i.id)}
                                        title={"Delete Column"}
                                >
                            <span className={"icon"}>
                                <FontAwesomeIcon icon={faTrash}/>
                            </span>
                                </button>
                            </div>
                        </div>
                    </td>
                    <td><CompsFieldInput record={i} field={ColumnFieldsDictionary.db_name}
                                         setRecord={(c) => updateColumn(c)}/></td>
                </tr>
    ) : <tr></tr>


    return (
        <div className={(!active_component) ? "is-hidden" : ""}>

            <div className={""}>
                <table className={"table is-fullwidth has-text-centered "}>
                    <thead>
                    <tr>
                        <th className={"has-text-centered"}>Field</th>
                        <th className={"has-text-centered"}>{props.options ? "Value" : "Column"}</th>

                    </tr>
                    </thead>
                    <tbody>{itemList}</tbody>
                </table>

                <div className={"has-text-centered"}>
                    <button className={"button is-success is-inverted pulse is-small is-rounded p-3"} onClick={newItem}
                            onMouseOver={(e) => e.currentTarget.classList.remove("pulse")}
                            title={"Add Column"}
                    >
                    <span className={"icon"}>
                        <FontAwesomeIcon icon={faPlus}/>
                    </span>
                    </button>
                </div>
            </div>


        </div>
    );
}

export default Cols;