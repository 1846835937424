import React from "react";

interface JSONBuilderProps {
    jsonStateTree: string,
    restructureState: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const JSONBuilder = (props: JSONBuilderProps) => <textarea key={"JSONBuilder"}
                                                   className={"textarea"}
                                                   value={props.jsonStateTree}
                                                   rows={20}
                                                   onChange={props.restructureState}
                                                 >
                                                 </textarea>

export default JSONBuilder