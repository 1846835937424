import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import {Link} from "react-router-dom";

function Footer() {
    const [activeTOS, activateTOS] = useState<boolean>(false);
    const [activePP, activatePP] = useState<boolean>(false);

    /*useEffect(() => {
        //add a handler to remove notifications when x is clicked
        (document.querySelectorAll(".notification .delete")).forEach(($delete: Element) => {
            $delete.addEventListener('click', () => {
                activateTOS(false)
            });
        });
    }, [activeTOS, activePP])*/

  return (
    <div>
        <div className={(activePP)?"container":" is-hidden"}>
            <div className={"notification has-text-centered"}>
                <button className="delete" onClick={() => activatePP(false)}></button>
                The site calls out to an Azure function app, no data is retained other than what Microsoft retains for logging
            </div>
        </div>

        <div className={(activeTOS)?"container":" is-hidden"}>
            <div className={"notification has-text-centered"}>
                <button className="delete" onClick={() => activateTOS(false)}></button>
                The software is provided "as-is."
            </div>
        </div>


        <footer className="footer">

            <div className="columns">
                <div className={"column has-text-centered"}>

                    <aside className="menu">
                        <p className="menu-label has-text-primary">
                            Contact
                        </p>
                        <ul className="menu-list">
                            <li>
                                <figure className="image is-64x64 is-inline-block">
                                <img src="cb.png" alt="berd"></img>

                                </figure>

                                <p className={""}>Chris Barsby</p>

                                <br />

                                    <a className={"is-inline-block"} href="https://www.linkedin.com/in/barsby" target={"_blank"} rel="noreferrer"> {/*style={{background: "#0a66c2"}}*/}
                                        <span className={"icon has-text-white box p-0 has-background-black"}>
                                            <FontAwesomeIcon icon={faLinkedinIn} />
                                        </span>
                                    </a>

                            </li>
                        </ul>
                    </aside>





                </div>
                <div className={"column is-4"}></div>
                <div className={"column is-2 has-text-centered"}>
                    <aside className="menu">
                        <p className="menu-label has-text-primary">
                            Menu
                        </p>
                        <ul className="menu-list">
                            {/* TODO - modal or something when these are clicked, cba to navigate */}
                            <li><Link to={"/"} className={"navbar-item"}>Home</Link></li>{/* What is berd */}
                            <li><Link to={"/builder"} className={"navbar-item"}>Builder</Link></li>{/* Diagram builder */}
                            <hr className="navbar-divider" />
                            <li><button className={"button is-ghost has-text-dark"} onClick={() => activatePP(true)}>Privacy Policy</button></li>{/* The site calls out to an Azure function app, no data is retained other than what Microsoft retains for logging */}
                            <li><button className={"button is-ghost has-text-dark"} onClick={() => activateTOS(true)}>Terms of Service</button></li>{/* The software is provided "as-is." */}
                        </ul>
                    </aside>
                </div>
                <div className={"column is-4"}></div>
                <div className={"column has-text-centered"}>
                    <aside className="menu">
                        <p className="menu-label has-text-primary">
                            Help
                        </p>
                        <ul className="menu-list">
                            {/*<li><a>Integration</a></li>*/}{/* TODO - Navigate to a page detailing the JSON schema and how to get an Excel into CSV into JSON and load in */}
                            {/*<li><a>Examples</a></li>*/}{/* Showcase of diagrams */}
                            <li><Link to={"/faq"} className={"navbar-item"}>FAQs</Link></li>
                            <li><Link to={"/examples"} className={"navbar-item"}>Examples</Link></li>
                        </ul>
                    </aside>
                </div>

            </div>
        </footer>








    </div>
  );
}

export default Footer;