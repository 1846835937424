import React from "react";
import { useAppSelector, useAppDispatch } from '../state/hooks'
import {
    addTable,
    Comp,
    delTable,
    CompFieldsDictionary, copyTable, getCompReference
} from "../state/slices/diagramSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlus, faTrash, faAngleUp, faAngleDown, faCopy} from '@fortawesome/free-solid-svg-icons'
import Cols from "./cols";
import ColorPicker from "./ColorPicker";
import CompsInput from "./CompsInput";

//TODO (future, not required)
//TODO - add hide/hidden box to comp to allow temp removal from diagram
//TODO - create colour picker and add to column and component to allow highlights/branding
//TODO - to reorder rows, use Grip dots, not burger


interface CompsProps {
    setActiveComp: any,
    active_component_id: number,
}



function Comps(props: CompsProps) {
    const items = useAppSelector(state => state.diagram.components)
    const existingComponentIds = useAppSelector(state => state.diagram.existingComponentIds)
    const colors = useAppSelector(state => state.diagram.colors)

    const dispatch = useAppDispatch()

    /*const [showNewRowForm, setShowNewRowForm] = useState<boolean>(false);
    const [compId, setCompId] = useState<number>(0);
    const [compName, setCompName] = useState<string>("");
    const [compDBName, setCompDBName] = useState<string>("");
    const [compAlias, setCompAlias] = useState<string>("");
    const [compDescription, setCompDescription] = useState<string>("");*/



    const newItem = (e: React.MouseEvent<HTMLButtonElement>) => {
        //e.currentTarget.classList.remove("pulse");
        let new_id = ( ( existingComponentIds.length > 0 ) ? Math.max( ...existingComponentIds, 0 ) + 1 : 1 );
        dispatch(addTable({
            id: new_id,
            columns: [],
            colors: {},
        }))
        props.setActiveComp(new_id);
    }

    /*const cancelForm = () => {

        setShowNewRowForm(false);
        setCompId(0);
        setCompName("");
        setCompDBName("");
        setCompAlias("");
        setCompDescription("");
        props.setActiveComp(0);
    }*/

    /*const saveForm = () => {
        const t: Comp = {
            "id": ( compId > 0 )? compId: ((existingComponentIds.length > 0)?existingComponentIds[existingComponentIds.length - 1] + 1: 1),
            "columns": (props.active_component_id && props.active_component_id > 0 && items[props.active_component_id])? items[props.active_component_id].columns:[]
        };
        if ( compName != "" ) { t["name"] = compName }
        if ( compDBName != "" ) { t["db_name"] = compDBName }
        if ( compDescription != "" ) { t["description"] = compDescription }
        if ( compAlias != "" ) { t["alias"] = compAlias }
        dispatch(addTable(t))
        cancelForm()
        setShowNewRowForm(true);
    }*/

    const binComp = (item_id: number) => {
        dispatch(delTable(item_id))
    }

    const copyComp = (item_id: number) => {
        dispatch(copyTable(item_id))
    }




    /*const itemList = Object.keys(items).map((i, n) => {
            let iAmActive = props.active_component_id === comp.id;

            const activateRow = (id: number) => {
                props.setActiveComp(id);
            }
            const toggleRow = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
                //e.stopPropagation();
                if (iAmActive) {
                    setCompId(0);
                    setCompName("");
                    setCompDBName("");
                    setCompAlias("");
                    setCompDescription("");
                }

                activateRow(iAmActive?0:comp.id)
            }

            return (
                <tr key={comp.id} data-key={comp.id}
                    onClick={toggleRow}
                    className={"has-text-centered" + ((iAmActive) ? " is-selected" : "") + ((comp.columns.length > 0)?" has-text-weight-medium":"")}
                    >

                        <td><Input record={comp} field={CompFieldsDictionary.name} setRecord={(c) => dispatch(addTable(c))} activateRecord={activateRow} /></td>
                        <td><Input record={comp} field={CompFieldsDictionary.db_name} setRecord={(c) => dispatch(addTable(c))} activateRecord={activateRow} /></td>
                        <td><Input record={comp} field={CompFieldsDictionary.alias} setRecord={(c) => dispatch(addTable(c))} activateRecord={activateRow} /></td>
                        <td><Input record={comp} field={CompFieldsDictionary.description} setRecord={(c) => dispatch(addTable(c))} activateRecord={activateRow} /></td>
                        <td>


                    </td>
                </tr>
            )
    })*/


    const itemAccordian = Object.entries(items).map(([position, comp]) => {
            let iAmActive = props.active_component_id === comp.id;

            const activateRow = (id: number) => {
                props.setActiveComp(id);
            }
            const addGrey = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                if (!iAmActive) {
                    e.currentTarget.classList.add("has-background-grey-lighter")
                }
            }
            const removeGrey = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.currentTarget.classList.remove("has-background-grey-lighter")
            }
            const toggleRow = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                //e.stopPropagation();
                /*if (iAmActive) {
                    setCompId(0);
                    setCompName("");
                    setCompDBName("");
                    setCompAlias("");
                    setCompDescription("");
                }*/
                removeGrey(e)
                activateRow(iAmActive?0:comp.id)
            }

            return (
                <div className={"card is-shadowless"}
                     key={comp.id} data-key={comp.id}
                >
                    <header className={"card-header" +
                        ((iAmActive) ? " has-background-primary has-text-light" : "")
                    }
                            onClick={toggleRow}
                            onMouseEnter={addGrey}
                            onMouseLeave={removeGrey}
                    >
                        <p className={"card-header-title" +
                            ((comp.columns.length > 0) ? " has-text-weight-medium" : " has-text-weight-light") +
                            ((iAmActive) ? " has-text-light" : "")
                        }
                        >
                            {getCompReference(comp)}
                        </p>

                        {(iAmActive) ? <>
                                <button className={"card-header-icon "}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            binComp(comp.id)
                                        }}
                                        title={"Delete Component"}
                                >
                                <span className={"icon"}>
                                    <FontAwesomeIcon icon={faTrash}/>
                                </span>
                                </button>
                                <button className={"card-header-icon  "} onClick={(e) => {
                                    e.stopPropagation();
                                    copyComp(comp.id)
                                }}
                                        title={"Copy Component"}
                                >
                            <span className={"icon"}>
                                <FontAwesomeIcon icon={faCopy}/>
                            </span>
                                </button>
                            </>
                            : null}
                        <button className="card-header-icon" aria-label="more options">
                          <span className="icon">
                            {(iAmActive) ? <FontAwesomeIcon icon={faAngleUp}/> : <FontAwesomeIcon icon={faAngleDown}/>}
                          </span>
                        </button>
                    </header>
                    {
                        (iAmActive) ?
                            <div className={"card-content"}>
                                <div className={"block"}>
                                    <div className="field is-horizontal">
                                        <div className="field-label is-normal">
                                            <label className="label">Name</label>
                        </div>
                        <div className="field-body">
                                    <div className="field has-addons">
                                        <p className="control is-expanded">
                                            <CompsInput record={comp} field={CompFieldsDictionary.name}
                                                        setRecord={(c) => dispatch(addTable(c))}
                                                        activateRecord={activateRow}/>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">Table</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control">
                                            <CompsInput record={comp} field={CompFieldsDictionary.db_name} setRecord={(c) => dispatch(addTable(c))} activateRecord={activateRow} />
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">Alias</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control">
                                            <CompsInput record={comp} field={CompFieldsDictionary.alias} setRecord={(c) => dispatch(addTable(c))} activateRecord={activateRow} />
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">Description</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control">
                                            <CompsInput record={comp} field={CompFieldsDictionary.description} setRecord={(c) => dispatch(addTable(c))} activateRecord={activateRow} />
                                        </p>
                                    </div>
                                </div>
                            </div>



                                        <ColorPicker color={(comp.colors ?? {})["Background"] ?? colors["Background"]} setColor={(kv) => {
                                            let c: Comp = {
                                                ...comp,
                                                colors: {...comp.colors}  //clone so I can modify
                                            };
                                            (c.colors ?? {})[kv.key] = kv.value
                                            dispatch(addTable(c))
                                        }}
                                            name={"Background"}
                                            label={"Background"}
                                            boldLabel={true}
                                        />



                        </div>

                        <div>
                            <Cols active_component_id={props.active_component_id}/>
                        </div>
                    </div>:<div></div>}

                </div>

            )
    })

    //reset the form with values when the comp is clicked to allow editing
    /*useEffect(() => {
        if (props.active_component_id > 0) {
            let item = items[props.active_component_id];
            if ( item ) {
                setCompId(item.id ?? 0);
                setCompName(item.name ?? "");
                setCompDBName(item.db_name ?? "");
                setCompAlias(item.alias ?? "");
                setCompDescription(item.description ?? "");
            }
        } else {

        }
    }, [props.active_component_id])*/

    return (
        <div className={""}>
            <div className={"block"}>
                {itemAccordian}
            </div>

            <div className={"has-text-centered"}>
                <button className={"button is-success is-inverted pulse is-rounded p-4"} onClick={newItem} onMouseOver={(e) => e.currentTarget.classList.remove("pulse")}
                    title={"Add Component"}
                >
                    <span className={"icon"}>
                        <FontAwesomeIcon icon={faPlus} />
                    </span>
                </button>
            </div>

        </div>
    );
}

export default Comps;