import React, {useEffect} from 'react';
import './App.css';
import Builder from "./comps/builder";
import Home from "./comps/home";
import { store } from './state/store';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Menu from "./comps/menu";
import Footer from "./comps/layout/footer";
//import ReactDOM from "react-dom/client";
import FAQ from "./comps/FAQ";
import Examples from "./comps/examples";
import {onStateChange} from "./state/localStorage";

//add configureStore once I start using thunks https://redux.js.org/usage/configuring-your-store

//debug function to write to console when developing (when running on localhost)
export function localconsole(s: any) {
    if(window.location.hostname == "localhost") {
        console.log(s)
    }
}

function onStoreChange() {
    let state = store.getState();
    onStateChange(state);
}

// subscribe to changes to the store, so that the changed state is persisted locally
//const unsubscribe =
store.subscribe(onStoreChange);


function App() {

    useEffect(() => {
        //modify body to fit navbar properly
        document.body.classList.add("has-navbar-fixed-top");

    }, []);



  return (
      <Provider store={store}>

          <BrowserRouter>
              <Menu/>
            <Routes>
                <Route index element={<Home />} />
                <Route path="builder" element={<Builder />} />
                <Route path={"faq"} element={<FAQ />} />
                <Route path={"examples"} element={<Examples />} />
            </Routes>
              <Footer />
          </BrowserRouter>


      </Provider>
  );
}

export default App;
