import React from "react";
import QA from "./QA";



function FAQ() {

    return (
      <div style={{height: "100%"}}>
          <section className="hero is-primary">
                    <div className="hero-body">
                        <p className="title">
                            FAQs
                        </p>
                    </div>
          </section>
          <div className={"hero is-halfheight "}>
              <div className={"hero-body container"}>
                  <div className="tile is-ancestor">
                      <div className="tile is-vertical">

                          {/* Row 1 */}
                          <div className="tile">

                              <QA q={"Why?"}
                                  a={"I wasn't aware of a tool that can create these types of diagrams that doesn't require a lot of time or effort, and I wanted something system-agnostic and reusable as I make these diagrams all the time!"}
                              />

                              <QA q={"Is the builder a bit slow sometimes?"}
                                  a={"It may take a few seconds to render changes to the diagram, it's hosted on a free cloud tier in the UK and is subject to usage limits accordingly. No throttling is currently in place but that may change in future"}
                              />

                              {/*<QA q={"How are the diagrams generated?"}
                                a={(<a href={"https://plantuml.com/faq"} target={"_blank"}>PlantUML</a>)}
                            />*/}

                              <QA q={"Can I customise these diagrams?"}
                                  a={"Colours can be changed, but rendering is left to the engine. The generated SVGs can be edited with an appropriate tool if you're desperate"}
                              />

                          </div>

                          {/* Row 2 */}

                          <div className="tile">

                              <QA q={"What's the engine?"}
                                  a={<span>
                                    <a className={""} href="https://plantuml.com/"
                                       target={"_blank"} rel="noreferrer"
                                    >
                                    {"PlantUML"}
                                    </a>
                                      {"! It's an awesome text based drawing tool that I've been using for years." +
                                          " This tool combines different diagram types and adds an abstraction " +
                                          "layer to allow the simple and flexible interface for making these mixed " +
                                          "conceptual diagrams. The original intention was to allow easily drawing database " +
                                          "schemas, but as I find the need, I expand the functionality to make more complex " +
                                          "diagrams. Every diagram you generate here can be generated directly with "}
                                      <a className={""} href="https://plantuml.com/"
                                         target={"_blank"} rel="noreferrer"
                                      >
                                    {"PlantUML"}
                                      </a>.

                                     </span>}
                              />


                          </div>


                      </div>
                  </div>


              </div>
          </div>
      </div>

    );
}

export default FAQ;