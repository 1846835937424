import React from "react";
import {KVProps} from "../state/slices/diagramSlice";
import ColorInput from "./ColorInput";

interface PickerProps {
    name: string,
    color: string,
    setColor: (arg0: KVProps)=>void,
    label?: string,
    centered?: boolean,
    boldLabel?: boolean,
}

const isColor = (color: string) => {
  const optionStyle = new Option().style;
  optionStyle.color = color;
  return optionStyle.color !== '';
}



function ColorPicker(props: PickerProps) {
    const theColor = isColor(props.color) ? props.color : ""
    return (
        <div className="field is-horizontal">
            {
                props.label ?
                    <div className={`field-label is-normal`}>
                        {/*<label className="label has-text-weight-light">{props.label}</label>*/}
                        <label className={props.boldLabel ? "label" : ""}>{props.label}</label>
                    </div>
                :
                    <div></div>
            }

            <div className="field-body">
                <div className={`field is-expanded has-addons ${(props.centered ? "has-addons-centered" : "")}`}>
                    <div className="control is-expanded">
                    <ColorInput color={props.color} setColor={props.setColor} name={props.name}/>
                    </div>
                    <div className="control">
                        <div className="button is-static" style={
                            {
                                background: theColor,
                                color: theColor,
                                borderColor: "transparent"
                            }
                        }
                        >
                            {" "}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ColorPicker;