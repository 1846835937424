//fontawesome diagram-project

import React, {useEffect, useState} from "react";
import Diagram from "./diagram";
import Comps from "./comps";
import Ships from "./relationships";
import Options from "./options";
import {useAppDispatch, useAppSelector} from "../state/hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCode, faTable, faTrash, faUpload, faPlus, faArrowDownAZ, faDiagramProject} from "@fortawesome/free-solid-svg-icons";
import {
    Comp,
    CompDictionary,
    DiagramSlice, OptionDictionary, ColorDictionary,
    replaceSlice,
    resetSlice, Ship,
    ShipDictionary,
    sliceInitial, stateToString, sortComps, sortShips
} from "../state/slices/diagramSlice";
import { newProject, removeProject, changeProject, updateProject } from "../state/slices/projectSlice"
import JSONBuilder from "./JSONBuilder";
import {localconsole} from "../App";
import Events from "./events";

//TODO - refactor the Tabs to show all or nothing based on active Tab. Allow for different Tabs and sort out defaults etc, with introduction of Gantt this is getting messy
// Getting confused when using option to display the Component builder vs Events

// this will throw, which is used by builder to set JSONValid value to indicate when there is an issue in converting
export function restructureState(jsonString: string) {
    localconsole("ENTER: restructureState")
    let tree = JSON.parse(jsonString)

    //transform array to object
    let comp: CompDictionary = {}
    let ship: ShipDictionary = {}
    let options: OptionDictionary = {}
    let colors: ColorDictionary = {}
    let compIds: number[] = [];
    let shipIds: number[] = [];
    if (Array.isArray(tree.components)) {
        tree.components.forEach((c: Comp, i: number) => {
            let inc = (i + 1).toString();
            comp[inc] = {...c, id: i + 1};
            let colInc = 1;
            comp[inc].columns.forEach((column) => {
                column.id = colInc
                colInc = colInc + 1
            })
        })
        compIds = tree.components.map((c: object, i: number) => i + 1)
    } else {
        if (typeof tree.components === "object") {
            comp = tree.components
            compIds = Object.keys(tree.components).map((c: string, i: number) => i + 1)
        }
    }

    localconsole("Checking for relationships attribute")
    if (tree.relationships && !tree.ships) {
        tree.ships = tree.relationships
    }
    if (Array.isArray(tree.ships)) {
        tree.ships.forEach((s: Ship, i: number) => {
            let inc = (i + 1).toString();
            ship[inc] = {...s, id: i + 1}
        })
        shipIds = tree.ships.map((s: object, i: number) => i + 1)
    } else {
        if (typeof tree.ships === "object") {
            ship = tree.ships
            shipIds = Object.keys(tree.ships).map((c: string, i: number) => i + 1)
        }
    }
    localconsole("Checking for options attribute")
    for (let o in tree.options) {
        options[o] = !!tree.options[o]
    }
    localconsole("Checking for colors attribute")
    for (let o in tree.colors) {
        colors[o] = tree.colors[o]
    }
    let restructured: DiagramSlice = {
        ...sliceInitial,
        existingComponentIds:  compIds,
        components: comp,
        existingShipIds: shipIds,
        ships: ship,
        options: options,
        colors: colors
    }
    return restructured;
}

function Builder() {
    //const existingProjectIds = useAppSelector(state => state.project.existingProjectIds)
    const projects = useAppSelector(state => state.project.projects)
    const currentProject = useAppSelector(state => state.project.currentProject )
    const existingComponentIds = useAppSelector(state => state.diagram.existingComponentIds)
    const components = useAppSelector(state => state.diagram.components)
    const ships = useAppSelector(state => state.diagram.ships)
    const diagramStateTree = useAppSelector(state => state.diagram)
    const diagramOptions = useAppSelector(state => state.diagram.options)

    const dispatch = useAppDispatch()

    const [formsVisible, setFormsVisible] = useState<boolean>(true);
    const [activeComp, setActiveComp] = useState<number>(0);
    const [activeShip, setActiveShip] = useState<number>(0);
    const [codeView, setCodeView] = useState<boolean>(false);
    const [jsonStateTree, setJsonStateTree] = useState<string>(stateToString(diagramStateTree));
    const [jsonValid, setJsonValid] = useState<number>(0);  //keep adding padding if JSON is updated incorrectly
    const [activeTab, setActiveTab] = useState<string>("Components");

    const projectOptions = projects.map((p, n) => {
        return (<option key={p.name}>{p.name}</option>)
    })

    function convertJsonToStateTree(s: string, file_name: string | null) {
        let updatedJson = s
        setJsonStateTree(updatedJson)
        //console.log("Direct edit")
        //console.log(updatedJson)
        try {
            let restructured: DiagramSlice = restructureState(updatedJson);
            //console.log(JSON.stringify(restructured))
            //setJsonStateTree(stateToString(restructured))

            dispatch(replaceSlice(restructured))

            setJsonValid(0)
        } catch(e) {
            console.log("Error converting json to state, markup the editor", e)
            setJsonValid(((jsonValid<6)?jsonValid + 1:6))
            //console.log(jsonValid)
        }
    }



    const addProject = (e: React.MouseEvent<HTMLButtonElement>) => {
        //e.currentTarget.classList.remove("pulse");
        let input = window.prompt("New Project Name:");
        if (input){
            //let new_id = ( ( existingProjectIds.length > 0 ) ? Math.max( ...existingProjectIds, 0 ) + 1 : 1 );
            dispatch(newProject({
                name: input,
                diagram: stateToString(diagramStateTree, 0)
            }));
            //let select = document.getElementById("projectSelect") as HTMLSelectElement | null;
            //if ( select ) {
                //console.log("Updating project selection on new")
                //select.value = input
            //} else {
                //console.log("Could not update project selection on new")
            //}

        }

    }

    //update the project automatically when the diagram changes
    useEffect(() => {
        let diagramStateTree_string = stateToString(diagramStateTree)
        dispatch(updateProject({
            name: currentProject,
            diagram: diagramStateTree_string,
        })
        )
        setJsonStateTree(diagramStateTree_string)
        setJsonValid(0)
    }, [dispatch, diagramStateTree, currentProject]);

    useEffect(() => {
        if ( Object.keys(components).length === 0 && Object.keys(ships).length === 0 ) {
            let newStateTree = JSON.stringify({
                ...sliceInitial,

                components: [
                    {
                        columns: [],
                        name: "Add Components"

                    },
                    {
                        columns: [],
                        name: "Add Columns"
                    },
                    {
                        columns: [],
                        name: "Add Relationships"
                    }
                ],
                ships: [
                    {
                        from: "Add Components",
                        to: "Add Columns",
                        cardinality: "DoT"
                    },
                    {
                        from: "Add Columns",
                        to: "Add Relationships",
                        cardinality: "ArO"
                    },
                    {
                        from: "Add Components",
                        to: "Add Relationships",
                        cardinality: "ArO"
                    }
                ],


            });
            setJsonStateTree(newStateTree)
            try {
                let restructured: DiagramSlice = restructureState(newStateTree);
                dispatch(replaceSlice(restructured))
                setJsonValid(0)
            } catch(e) {
                setJsonValid(((jsonValid<6)?jsonValid + 1:6))
            }

        }
    }, [components, ships, dispatch, jsonValid]);

  return (
      <div style={{height: "100%"}}>
        <section className="hero is-primary">
                    <div className="hero-body">
                        <p className="title">
                            Builder
                        </p>
                    </div>
        </section>
          <div className="columns">
              {/*Left side*/}

                  <div className={"column is-2 has-text-centered-mobile box m-3" + ((formsVisible)?"":" is-hidden")}>

                      {/* Diagram Menu / Project Selector */}
                      <aside className="menu p-2">
                          <p className="menu-label has-text-primary">
                              Project
                          </p>
                          <div className="field has-addons">
                              <div className="control is-expanded">
                                  <div className="select is-fullwidth">
                                      <select id={"projectSelect"} onChange={ (e) => {
                                          let project_slice = projects.filter(p => p.name === e.currentTarget.value )
                                          //console.log("Switching to slice:")
                                          //console.log(project_slice[0].diagram)
                                          dispatch( replaceSlice(restructureState(project_slice[0].diagram)) )
                                          dispatch(changeProject(e.currentTarget.value))
                                      }
                                      }
                                                            value={currentProject}
                                      >
                                          {projectOptions}
                                      </select>
                                  </div>
                              </div>
                              <div className="control">
                                  <button className="button is-inverted has-text-success" onClick={addProject}>
                                      <span className="icon">
                                          <FontAwesomeIcon icon={faPlus} />
                                      </span>
                                  </button>
                              </div>
                          </div>
                      </aside>
                      <div className={"buttons  is-centered"}>

                        <button className={"button"}
                                onClick={() => {
                                    //console.log(`Deleting project ${currentProject}`)
                                    setJsonValid(0);
                                    dispatch(removeProject(currentProject));

                                    //don't get into a weird state, load the next diagram
                                    if ( projects.length > 0 ) {
                                        for (let p = projects.length; p > 0; p--) {
                                            if (projects[p-1].name !== currentProject) {
                                                dispatch( replaceSlice(restructureState(projects[p-1].diagram)) )
                                                dispatch(changeProject(projects[p-1].name))
                                            }
                                        }
                                    } else {
                                        dispatch(resetSlice())
                                    }
                                }
                        }
                        >
                            <span>Discard</span>
                            <span className={"icon has-text-danger"}>
                                <FontAwesomeIcon icon={faTrash} />
                            </span>
                        </button>

                      </div>

                  </div>



              {/*Right side*/}

                  <div className="column">
                      <div className={"hero is-halfheight "}>

                    <div className={"hero-body container"}>

            <div className={"columns"}>
                <div className={"column" + ((formsVisible)?"":" is-hidden")}>

                    <div className={""}>
                        <div className={"block"}>
                            <div className="box">
                                <div className={"level"}>
                                    <div className={"level-left"}>
                                        <div className={"level-item"}>
                                            <div className={"subtitle"}>
                                                <div className="tabs">
                                                    <ul>
                                                        {!diagramOptions.Gantt ? <li className={activeTab === "Components" ? "is-active" : ""}>
                                                                            <a data-tab={"Components"}
                                                                                onClick={(e) => {
                                                                                   setActiveTab(e.currentTarget.getAttribute("data-tab") ?? "");
                                                                                   setCodeView(false);
                                                                                }
                                                                                }>Components</a>
                                                                        </li>:<></>}
                                                        {diagramOptions.Gantt ? <li className={activeTab === "Events" ? "is-active" : ""}>
                                                                            <a data-tab={"Events"}
                                                                               onClick={(e) => {
                                                                                   setActiveTab(e.currentTarget.getAttribute("data-tab") ?? "");
                                                                                   setCodeView(false);
                                                                               }
                                                                               }>Events</a>
                                                                        </li>:<></>}
                                                            <li className={activeTab === "Relationships" ? "is-active" : ""}>
                                                                            <a data-tab={"Relationships"}
                                                                               onClick={(e) => {
                                                                                   setActiveTab(e.currentTarget.getAttribute("data-tab") ?? "");
                                                                                   setCodeView(false);
                                                                               }
                                                                               }>Relationships</a>
                                                                        </li>
                                                    <li className={activeTab === "JSON" ? "is-active" : ""}>
                                                        <a className="" data-tab={"JSON"}
                                                           onClick={(e) => {
                                                               setActiveTab(e.currentTarget.getAttribute("data-tab") ?? "");
                                                               setCodeView(true)
                                                           }
                                                           }>

                                                                <span className={"icon"}>

                                                                    <FontAwesomeIcon icon={faDiagramProject}/>

                                                                </span>
                                                            <span>JSON</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className={"level-right"}>

                                    {(!codeView) ?
                                        <div className={"level-item"}>
                                            <button className={"button is-fullwidth"}
                                                    onClick={() => {
                                                        switch(activeTab) {
                                                            case "Components":
                                                                dispatch(sortComps())
                                                                break
                                                            case "Relationships":
                                                                dispatch(sortShips())
                                                                break
                                                            case "Events":
                                                                break
                                                            default:
                                                                break
                                                        }
                                                    }}>
                                            <span className={"icon"}>
                                                <FontAwesomeIcon icon={faArrowDownAZ}/>
                                            </span>

                                            </button>
                                        </div>
                                            : <></>
                                        }

                                    </div>
                                </div>


                                {(codeView) ?
                                    <div
                                        className={((jsonValid === 0) ? "" : (" has-background-danger p-" + jsonValid))}>
                                        <div className={"card is-shadowless"}>
                                            <JSONBuilder key={"jsonbuilding"} jsonStateTree={jsonStateTree}
                                                         restructureState={(e) => convertJsonToStateTree(e.currentTarget.value, null)}/>
                                        </div>
                                    </div> :
                                    (()=>{
                                        switch(activeTab) {
                                            case "Components":
                                                return !diagramOptions.Gantt ? <div><Comps setActiveComp={setActiveComp} active_component_id={activeComp} /></div> : <></>
                                            case "Relationships":
                                                return <div
                                                    className={"has-text-centered-mobile" + ((existingComponentIds.length === 0) ? " is-hidden" : "")}>
                                                    <Ships setActiveShip={setActiveShip} active_ship_id={activeShip} />
                                                </div>
                                            case "Events":
                                                return diagramOptions.Gantt ? <div>
                                                    <Events setActiveComp={setActiveComp} active_component_id={activeComp} />
                                                </div> : <></>
                                            default:
                                                return <></>
                                        }
                                    })()
                                }
                            </div>
                        </div>
                        {/*<div className={"field has-text-centered"}>
                            <div className="control">
                                <button className={"button is-danger"} onClick={() => {setJsonValid(0); dispatch(resetSlice())}}>
                                    <span className={"icon"}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </span>
                                </button>
                            </div>
                        </div>*/}
                    </div>
                </div>


                <div className={"column container"}>

                    <Diagram key={"berd"} isExpanded={!formsVisible} setFormsVisible={setFormsVisible}/>

                    <Options/>
                </div>

            </div>
                    </div>
                      </div>
                  </div>


          </div>

      </div>

  );
}

export default Builder;